@media (max-width: 575px) {
    .responsive-margin {
        margin-top: 20px;
    }

    .extra-padding {
        margin-top: 20px;
        padding-left: 12px !important;
    }

    .footer .footer-top .extra-padding:after {
        display: none;
    }

    .dropdown-menu {
        position: relative !important;
        transform: none !important;
    }

    .nav-link {
        margin-top: 5px;
    }

    .dropdown-menu-right .dropdown-submenu-container .dropdown-menu {
        box-shadow: none;
        left: auto;
        margin-right: 10px;
    }

    .dropdown-item {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .feature-area .feature-wrapper .single-feature-col {
        width: 100%;
    }

    .feature-area .feature-wrapper .single-feature-col .single-feature .feature-content {
        width: 100%;
        margin-top: 10px;
    }

    .feature-area .feature-wrapper {
        display: block;
    }

    .feature-area .feature-wrapper .single-feature-col .single-feature {
        display: block;
        text-align: center;
    }

    .feature-area .feature-wrapper .single-feature-col .single-feature .feature-icon {
        text-align: center;
        justify-content: center;
        width: 100%;
    }

    .product-box {
        margin-bottom: 15px;
    }

    .categories .categories-start .categories-left {
        padding: 15px !important;
        padding-top: 0px !important;
    }

    .categories-headings {
        margin-bottom: 10px;
    }

    .categories .categories-start .categories-left .categories-headings p {
        margin-top: 10px;
    }

    .categories .categories-start .categories-left .categories-headings h2 {
        margin-bottom: 10px;
    }

    .hero-slide-content .title-1 {
        font-size: 30px;
    }

    .hero-slide-content .title-2 {
        font-size: 30px;
        margin-bottom: 0px;
    }

    .hero-slide-content .price {
        margin-left: -20px;
        font-size: 14px;
    }

    .hero-slide-content .banner-subheading {
        font-size: 20px;
        line-height: 30px;
        margin-top: 10px;
    }

    .filters {
        position: relative;
        width: 100%;
        top: 20px
    }

    #banner-1 {
        margin-top: -185px !important;
    }

    #banner-2 {
        margin-top: 130px;
    }

    .carousel-item {
        height: 100%;
    }

    .products-list {
        width: 100%;
        position: relative;
    }

    .pagination-list {
        justify-content: center;
    }

    .carousel-inner {
        height: 650px;
    }

    #align-center {
        align-items: flex-start;
    }

    .carousel-caption {
        height: 650px;
        padding-top: 0px;
    }

    .carousel-control-next {
        right: 10px;
    }

    .carousel-control-prev {
        left: 10px;
    }

    .login_wrap {
        margin-bottom: 15px;
    }

    .cart-total-box {
        margin-top: 10px;
    }

    .product-detail-section .detail-gallery .thumbnails-list {
        height: auto;
        display: flex;
        align-items: center;
        padding-left: 12px;
        padding-right: 12px;
    }

    .sticky-pos {
        position: relative;
    }

    .product-detail-section .detail-gallery .thumbnails-list .product-image {
        padding-left: 5px;
        padding-right: 5px;
    }

    .product-detail-section .detail-gallery .thumbnails-list .product-image img {
        width: 100%;
    }

    .detail-info {
        margin-top: 15px;
    }

    .product-detail-section .detail-info .attr-detail .color-list {
        flex-wrap: wrap;
    }

    .product-detail-section .detail-info .attr-detail .color-list li {
        margin-bottom: 5px;
    }

    .action-buttons {
        display: block;
    }

    .add-to-cart-btn {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }

    .buy-now-btn {
        width: 100%;
        margin-left: 0px;
        margin-top: 10px;
    }

    .quantity-counter {
        width: 100%;
        justify-content: center;
    }

    .order-review {
        margin-top: 15px;
    }

    .same-height {
        max-width: 100% !important;
        display: block !important;
        flex: 100% !important;
    }

    .category-box-grid-center {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .new-arrivals .product-box {
        height: auto !important;
        margin-top: 10px !important;
    }

    .new-arrivals .product-box img {
        height: 300px !important;
    }

    .bestsellers .product-box img {
        height: 100% !important;
    }

    .product-box img {
        height: 300px !important;
    }

    .topleft {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    .sold-out-image {
        display: none;
    }
    .sold-out-text{
        display: block;
    }

    .clearance-sale {
        background-size: auto !important;
    }

    .rewards-right {
        display: none;
    }

    .feature-icons {
        display: block;
    }

    .feature-icons .features-section-one {
        margin-right: 0px;
        margin-left: 0px !important;
        margin-bottom: 25px;
    }

    .rewards-background .left-rewards h2 {
        text-align: center;
    }

    .left-rewards {
        text-align: center;
    }

    .wholesale-banner {
        background-size: cover;
    }

    .wholesale-bg-section {
        background-size: cover;
    }

    .rewards-text {
        margin-left: 0px !important;
    }

    #rewards-buttons {
        display: block !important;
    }

    .rewards-text .signup-btn {
        width: 100%;
        margin-bottom: 10px;
    }

    .rewards-text .login-btn {
        width: 100%;
        margin-left: 0px !important;
    }

    .steps-detail {
        margin-bottom: 30px;
    }

    .benefit-card {
        margin-bottom: 20px;
    }

    .benefit-card.new-box {
        border-right: 0px solid transparent !important;
    }

    .new-rewards-section .rewards-with-bg {
        padding: 30px 10px !important;
    }

    .no-results-found .no-category-found {
        margin-bottom: 10px;
    }

    #no-margin-mobile {
        margin-top: 0px !important;
    }

    .instagram-wall .section-heading a {
        font-size: 22px;
    }

    .submit-btn-rewards {
        margin-top: 10px;
    }

    #mobile-padd {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    .new-category-box {
        margin-bottom: 10px;
    }

    .view-all-link {
        text-align: end;
    }

    .shopping-summary .product-des .product-name {
        font-size: 15px;
    }

    .table-responsive {
        overflow-x: scroll;
    }

    .table-responsive::-webkit-scrollbar {
        display: none;
    }

    .product-detail-section .detail-gallery .product-image img {
        height: auto !important;
    }

    .product-detail-section .detail-gallery .thumbnails-list .product-image img {
        height: auto !important;
    }

    .new-category-box .new-category-image img {
        object-fit: cover;
        height: 200px;
    }

    #banner-3 {
        margin-top: 60px;
    }

    .hero-slide-content h5 {
        font-size: 18px;
    }

    .made-line {
        text-align: left !important;
    }

    .save-btn {
        margin-bottom: 10px;
    }

    .mobile-responsive-nothing {
        width: 300px;
    }

    .return-to-home {
        width: 200px !important;
        background-color: #000;
        font-family: 'Mulish', sans-serif;
        font-weight: 700;
        border-color: #000;
    }

    .new-category-box .category-name {
        margin-left: 0px;
    }

    .new-category-box .category-name h5 {
        font-size: 14px;
    }

    .wholesale-textbox {
        width: 100%;
        margin-top: 0px;
        padding: 30px;
    }
}