.main {
    min-height: calc(100vh - 91px);
}

.login_wrap {
    border: 1px solid #E2E9E1;
    padding: 30px;
    border-radius: 10px;
}

.login_wrap h3 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    font-size: 25px;
}

.login_wrap p {
    font-family: 'Mulish', sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #465B52;
}

.login_wrap .login-form .login-btn {
    background-color: #252525;
    border-color: #252525;
    box-shadow: none;
    padding: 8px 40px;
    font-family: 'Mulish', sans-serif;
    color: #FFF;
    border-radius: 5px;
    font-size: 15px;
}

.login_wrap .login-form .form-group {
    margin-bottom: 1rem;
}

.login_wrap .login-form .form-group .form-control {
    font-family: 'Mulish', sans-serif;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    height: 45px;
    box-shadow: none;
    padding-left: 20px;
    color: #161C27;
    font-size: 14px;
    width: 100%;
}

.forgot-pass {
    margin-bottom: 15px;
    font-size: 15px;
    color: #465B52;
    font-weight: 500;
    font-family: 'Mulish', sans-serif;
    display: flex;
    justify-content: flex-end;
}

.forgot-pass p {
    cursor: pointer;
    margin-bottom: 0px;
}

.forgot-pass p:hover {
    color: #005CB9;
}

.proceed-to-btn {
    padding: 12px 30px;
    border: 1px solid #000;
    border-radius: 4px;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 700;
    background-color: #FFF;
}

.proceed-to-btn:hover {
    background-color: #000;
    color: #FFF;
}