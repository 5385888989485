.product-detail {
  min-height: calc(100vh - 91px);
}

.product-detail-section .detail-gallery {
  position: relative;
}

.product-detail-section .detail-gallery .thumbnails-list {
  padding-left: 0px;
  padding-right: 0px;
  max-height: 620px;
  overflow: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

.product-detail-section .detail-gallery .thumbnails-list .product-image {
  text-align: center;
}

.product-detail-section .detail-gallery .thumbnails-list .product-image img {
  cursor: pointer;
  width: 85%;
  object-fit: cover;
  border-radius: 5px;
  height: 150px;
}

.product-detail-section .detail-gallery .product-image img {
  width: 100%;
  height: 600px;
  object-fit: cover;
  border-radius: 5px;
}

.product-detail-section .detail-info .title-detail {
  font-size: 30px;
  font-family: "Inter Tight", sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.product-detail-section .detail-info .product-price-cover {
  border-top: 1px solid #e2e9e1;
  border-bottom: 1px solid #e2e9e1;
  padding: 8px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.product-detail-section .detail-info .product-price-cover p {
  margin-bottom: 0px;
  font-size: 30px;
  font-weight: 700;
  font-family: "Mulish", sans-serif;
}

.product-detail-section .detail-info .product-price-cover p .per-yard {
  font-size: 20px;
}

.product-detail-section .detail-info .attr-detail {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.product-detail-section .detail-info .attr-detail p {
  font-family: "Mulish", sans-serif;
  margin-bottom: 0px;
}

.product-detail-section .detail-info .attr-detail .color-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.product-detail-section .detail-info .attr-detail .color-list .color {
  margin-right: 10px;
  margin-bottom: 10px;
  height: 42px;
  width: 42px;
  border-radius: 40px;
  border: 1px solid #ddd;
}

.color-options-section h5 {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 18px;
}

.color-options-section h5 span {
  font-family: "Mulish", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-left: 5px;
}

.product-detail-section .detail-info .attr-detail-size {
  display: flex;
  align-items: center;
}

.product-detail-section .detail-info .attr-detail-size p {
  font-family: "Mulish", sans-serif;
  margin-right: 10px;
  margin-bottom: 0px;
}

.product-detail-section .detail-info .attr-detail-size .size-list {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style-type: none;
  margin-left: 10px;
}

.product-detail-section
  .detail-info
  .attr-detail-size
  .size-list
  li.active
  .size {
  background-color: #000;
  color: #fff;
}

.product-detail-section .detail-info .attr-detail-size .size-list li .size {
  font-family: "Mulish", sans-serif;
  border: 1px solid #e5e5e5;
  color: #555;
  line-height: 30px;
  font-size: 14px;
  min-width: 30px;
  text-align: center;
  text-transform: uppercase;
  background-color: #fff;
  padding: 0 7px;
  border-radius: 4px;
  margin-right: 5px;
}

.product-detail-accordion .accordion-item .accordion-header .accordion-button {
  min-height: 3.5rem;
  line-height: 1.25rem;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: relative;
  font-weight: 800;
  font-size: 18px;
  cursor: pointer;
  background-color: #f9f9f9;
  border-radius: 5px;
  padding: 0px 10px;
  color: #000;
  font-family: "Mulish", sans-serif;
}

.product-detail-accordion
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  box-shadow: none !important;
}

.product-detail-accordion
  .accordion-item
  .accordion-header
  .accordion-button:focus {
  box-shadow: none !important;
}

.product-detail-accordion .accordion-item .accordion-body {
  font-family: "Mulish", sans-serif;
  background-color: #f9f9f9;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  font-size: 16px;
  color: #465b52;
}

.action-buttons {
  display: flex;
  align-items: center;
}

.add-to-cart-btn {
  background-color: #000;
  font-family: "Mulish", sans-serif;
  padding: 8px 40px;
  border: 1px solid #000;
  margin-left: 10px;
}

.buy-now-btn {
  margin-left: 10px;
  background-color: #fff;
  font-family: "Mulish", sans-serif;
  padding: 8px 40px;
  color: #000;
  border: 1px solid #000;
}

.storeType-selection {
  width: 100%;
  margin-left: 10px;
}

.quantity-counter {
  display: flex;
  align-items: center;
}

.quantity-counter .minus-counter {
  height: 42px;
  background-color: #000;
  border-color: #000;
  color: #fff;
  font-size: 16px;
  border-bottom-right-radius: 0px;
  border-top-right-radius: 0px;
}

.quantity-counter .count {
  height: 42px;
  width: 37px;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.quantity-counter .count p {
  margin-bottom: 0px;
  font-family: "Mulish", sans-serif;
  color: #fff;
}

.quantity-counter .plus-counter {
  height: 42px;
  background-color: #000;
  border-color: #000;
  color: #fff;
  font-size: 16px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.sticky-pos {
  position: sticky;
  top: 0;
  height: 100%;
}

.green-dot-active {
  position: absolute;
  top: 2px;
  right: 0;
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
}

.note h5 {
  font-family: "Mulish", sans-serif;
  margin-bottom: 5px;
  font-size: 16px;
}

.note p {
  font-family: "Mulish", sans-serif;
  margin-bottom: 3px;
  font-size: 14px;
}

/*ratings and reviews */
.rating {
  margin-block: 15px;
}
.rating-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
.rating-header h5 {
  font-size: 18px;
  font-weight: 600;
}
.rating-header .rate-btn {
  font-size: 16px;
  font-weight: 600;
  color: #2b5bd5;
  cursor: pointer;
}

.rating-header .rate-btn:hover {
  text-decoration: underline;
}
