.header-section {
  box-shadow: 2px 4px 8px rgb(51 51 51 / 25%);
}

.header-section .top-header {
  max-width: 100% !important;
  text-align: center;
  background-color: #3c2415;
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-section .top-header h5 {
  color: #fff;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  margin-bottom: 0px;
}

.header-section .header-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
}

.header-section .header-wrap .logo img {
  width: 190px;
  height: auto;
  cursor: pointer;
}

.header-section .header-wrap .header-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-section .header-wrap .header-nav .main-menu .nav-link {
  font-family: "Mulish", sans-serif;
  display: inline-block;
  font-size: 17px;
  font-weight: 600;
  line-height: 60px;
  padding: 0 17px;
  color: #000;
}

.header-section
  .header-wrap
  .header-nav
  .main-menu
  .sub-menu
  .dropdown-menu-right {
  text-align: left;
  border-radius: 5px;
  min-width: 250px;
  left: 20px !important;
  background: #fff;
  color: #000;
  box-shadow: -1px 10px 80px -15px rgb(0 0 0 / 30%);
  border: 0px solid #fff;
  padding: 20px 0;
}

.header-section
  .header-wrap
  .header-nav
  .main-menu
  .sub-menu
  .dropdown-menu
  .dropdown-item {
  display: block;
  font-family: "Mulish", sans-serif;
  color: #2d2d2d !important;
  line-height: 20px;
  font-size: 15px;
  padding: 12px 0px 12px 20px;
  text-transform: capitalize;
  font-weight: 500;
}

.header-section
  .header-wrap
  .header-nav
  .main-menu
  .sub-menu
  .dropdown-menu
  .dropdown-item:hover {
  background-color: rgba(225, 225, 225, 1);
}

.header-section
  .header-wrap
  .header-nav
  .main-menu
  .sub-menu
  .dropdown-menu
  .dropdown-item:focus {
  background-color: rgba(225, 225, 225, 1);
}

.dropdown-submenu-container a.dropdown-submenu::after {
  right: auto;
  top: 1.4em;
  margin-left: 0.5em;
  font-size: 14px;
  vertical-align: 0.185em;
}

.dropdown-menu-right .dropdown-submenu-container .dropdown-menu {
  left: 100%;
  right: auto;
  text-align: left;
  border-radius: 5px;
  min-width: 250px;
  background: #fff;
  color: #000;
  box-shadow: -1px 10px 80px -15px rgb(0 0 0 / 30%);
  border: 0px solid #fff;
  padding: 20px 0;
  margin-left: 10px;
}

.header-section .header-wrap .header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-section .header-wrap .header-right .header-actions {
  cursor: pointer;
}

.header-section .header-wrap .header-right .cart-action-icon {
  position: relative;
}

.header-section .header-wrap .header-right .cart-action-icon span.pro-count {
  background-color: #005cb9;
  position: absolute;
  right: -5px;
  top: -5px;
  color: #fff;
  height: 18px;
  width: 18px;
  border-radius: 100%;
  font-weight: 500;
  font-size: 11px;
  text-align: center;
  line-height: 18px;
}

.searchbar-below-header.show {
  display: block;
}

.searchbar-below-header.hide {
  display: none;
}

.searchbar {
  cursor: pointer;
}

.searchbar-below-header {
  background-color: #fff;
  padding-top: 15px;
  padding-bottom: 15px;
}

.search-btn {
  width: 100%;
  height: 100%;
  background-color: #3e3ef4;
  border-radius: 5px;
  font-family: "Mulish", sans-serif;
}

@media (max-width: 425px) {
  .header-section .header-wrap .logo img {
    width: 135px;
    height: auto;
    cursor: pointer;
  }
}
