.main {
    min-height: calc(100vh - 500px);
}

.account-tabs {
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    padding: 15px 30px;
    border-bottom: 1px solid #E2E9E1;
    border-radius: 0px !important;
    color: #000;
}

.account-tabs svg {
    margin-right: 10px;
    margin-top: -3px;
}

.account-tabs.active {
    background-color: #000 !important;
    color: #FFF;
}

.nav-pills {
    border-radius: 5px !important;
    border: 1px solid #E2E9E1;
}

.card {
    border: 1px solid #E2E9E1;
    border-radius: 0.25rem;
}

.card .card-header {
    padding: 1rem;
    background-color: #F7F8F9;
}

.card .card-header h5 {
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    font-weight: 700;
}

.card .card-body {
    padding: 1rem;
}

.card-body p {
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    font-size: #465B52;
    margin-bottom: 0px;
}

.card-body table {
    width: 100%;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
}

.card-body table th {
    padding: 0.5rem;
    border: 1px solid #E2E9E1;
}

.card-body table td {
    padding: 0.5rem;
    border: 1px solid #E2E9E1;
}

.saved-addresses h5 {
    font-size: 15px;
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
    margin-bottom: 0px;
}

.saved-addresses .address-box {
    position: relative;
    border: 2px solid rgb(225, 225, 225);
    border-radius: 5px;
    padding: 15px 20px;
    cursor: pointer;
    margin-top: 5px;
    font-family: 'Mulish', sans-serif;
}

.saved-addresses .address-box.selected {
    border: 2px solid green;
}

.saved-addresses .address-box.selected .selected-icon {
    display: block;
}

.saved-addresses .address-box:hover {
    border: 2px solid green;
}

.saved-addresses .address-box .selected-icon {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
}

.saved-addresses .address-box .address-details {
    position: relative;
}

.saved-addresses .address-box .address-details p {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    margin-bottom: 0px;
}

.saved-addresses .address-box .address-details .address {
    margin-top: 15px;
}

.saved-addresses .address-box .address-details .actions {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.saved-addresses .address-box .address-details .actions .edit-btn {
    padding: 0px;
    font-family: 'Mulish', sans-serif;
    color: grey;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
}

.saved-addresses .address-box .address-details .actions .edit-btn:hover {
    text-decoration: underline;
}


.saved-addresses .address-box .address-details .actions .delete-btn {
    padding: 0px;
    font-family: 'Mulish', sans-serif;
    color: red;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
}

.saved-addresses .address-box .address-details .actions .delete-btn:hover {
    text-decoration: underline;
}

.address-modal-title {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.address-modal-body .form-group {
    margin-bottom: 15px;
}

.address-modal-body .form-group label {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.add-address-btn {
    background-color: #3E3EF4;
    font-size: 15px;
    color: #FFF;
    font-family: 'Mulish', sans-serif;
}

.add-new-address-btn {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    padding: 0px;
    background-color: #FFF;
    border: 0px solid transparent;
    color: #000;
    font-size: 15px;
}

.add-new-address-btn:hover {
    background-color: #FFF;
    color: #000;
    text-decoration: underline;
}

.pagination-btn {
    background-color: #000 !important;
    border: 1px solid #000 !important;
    font-family: 'Mulish', sans-serif;
}

.pagination-btn.prev {
    margin-right: 5px;
}

.opacity-form-control {
    background-color: rgba(255, 255, 255, 0.5) !important;
    border: 1px solid #CFD3CE;
}

.order-details-review h4 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 600;
}

.order-details-review .address-box {
    position: relative;
    border: 2px solid rgb(225, 225, 225);
    padding: 15px 20px;
}

.order-details-review .address-box h5 {
    font-family: 'Mulish', sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.order-details-review .address-box h5 svg {
    margin-top: -3px;
    margin-right: 5px;
}

.order-details-review .address-box .selected-icon {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
}

.order-details-review .address-box .address-details {
    position: relative;
}

.order-details-review .address-box .address-details p {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    margin-bottom: 0px;
}

.order-details-review .address-box .address-details .address {
    margin-top: 15px;
}

.order-details-review .address-box .address-details .actions {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.pac-container {
    z-index: 99999999999 !important;
}

.phone-number-select .PhoneInputCountry {
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    padding: 15px;
    height: 45px;
    background-color: #FFF;
}

.phone-number-select .PhoneInputCountry .PhoneInputCountrySelect option {
    font-family: 'Mulish', sans-serif;
    color: #465B52;
    font-size: 15px;
    margin-bottom: 10px;
}

.phone-number-select .PhoneInputInput {
    font-family: 'Mulish', sans-serif;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    height: 45px;
    box-shadow: none;
    color: #161C27;
    font-size: 14px;
    padding: 6px 12px 6px 20px;
    width: 100%;
}

.phone-number-select .PhoneInputInput::placeholder {
    font-size: 13px;
}
