.shopping-summary {
    width: 100%;
}

.shopping-summary td {
    border-top: 1px solid #E2E9E1;
    border-bottom: 1px solid #e2e9e1;
    text-align: center;
    padding: 8px;
}

.shopping-summary .main-heading th {
    font-family: 'Mulish', sans-serif;
    border: 2px solid #E2E9E1;
    padding: 8px;
    text-align: center;
    font-size: 14px;
    color: #212529;
}

.shopping-summary tbody tr img {
    border-radius: 5px;
    max-width: 80px;
}

.shopping-summary .product-des .product-name {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
    color: #222222;
}

.shopping-summary .product-des p {
    color: #465B52;
    font-size: 13px;
    font-family: 'Mulish',sans-serif;
    margin-top: 5px;
    margin-bottom: 0px;
}

.shopping-summary .price span {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    color: #212529;
}

.shopping-summary .clear-cart-btn {
    font-family: 'Mulish', sans-serif;
    padding: 0px;
    background-color: #FFF;
    color: #8E8E90;
    font-size: 14px;
    border: 0px solid transparent;
}

.shopping-summary .clear-cart-btn:active {
    background-color: #FFF;
    color: #000;
}

.continue-shopping-button .continue-btn {
    font-family: 'Mulish', sans-serif;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 4px;
    color: #FFF;
    border: 1px solid #000;
    background-color: #000;
    cursor: pointer;
    transition: all 300ms linear 0s;
    letter-spacing: 0.5px;
    text-decoration: none;
}

.divider {
    position: relative;
    overflow: hidden;
    z-index: 9;
    text-align: center;
    height: auto;
}

.divider .finger-icon {
    background-color: #FFF;
    position: relative;
    padding: 0 20px;
    z-index: 1;
}

.divider::before, .divider::after {
    content: '';
    position: absolute;
    width: 100%;
    top: 50%;
    left: 0px;
    height: 0;
    border-top: 2.5px solid #e2e9e1;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.cart-total-box {
    border: 1px solid #E2E9E1;
    border-radius: 5px;
    padding: 30px;
}

.cart-total-box .heading {
    font-size: 18px;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 600;
    color: #000;
}

.cart-total-box table {
    width: 100%;
    margin-bottom: 1rem;
}

.cart-total-box table .cart_total_label {
    padding: 0.5rem;
    border: 2px solid #E2E9E1;
    font-family: 'Inter Tight', sans-serif;
    font-weight: 600;
}

.cart-total-box table .cart_total_amount {
    padding: 0.5rem;
    font-size: 16px;
    border: 2px solid #E2E9E1;
    font-family: 'Mulish', sans-serif;
}

.cart-total-box .continue-btn {
    font-family: 'Mulish', sans-serif;
    display: inline-block;
    font-size: 14px;
    font-weight: 700;
    padding: 12px 30px;
    border-radius: 4px;
    color: #FFF;
    border: 1px solid #000;
    background-color: #000;
    cursor: pointer;
    transition: all 300ms linear 0s;
    letter-spacing: 0.5px;
    text-decoration: none;
}

.delete-icon{
    cursor: pointer;
}

.cart-empty {
    margin-top: 15px;
}

.cart-empty h5 {
    font-family: 'Inter Tight', sans-serif;
    text-align: left;
    font-weight: 700;
}

.table-responsive {
    padding-bottom: 10px;
}

