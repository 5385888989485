.footer {
  color: #fff;
  background-color: #252525;
  margin-top: 40px;
}

.footer .footer-top {
  padding: 60px 0 30px 0;
}

.footer .footer-top .left-border {
  border-right: 1px solid #454545;
}

.footer .footer-top .single-wedge .footer-heading {
  font-family: "Mulish", sans-serif;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
  position: relative;
}

.footer .footer-top .single-wedge .footer-links a {
  font-family: "Mulish", sans-serif;
  line-height: 32px;
  margin-bottom: 8px;
  font-size: 15px;
  padding: 0;
  color: #a1a1a1;
  margin-bottom: 0;
  display: block;
  transition: all 300ms linear;
  text-decoration: none;
}

.footer .footer-top .single-wedge .footer-links a:hover {
  color: #8fd2ee;
}

.footer .footer-top .single-wedge .footer-links .last-wedge {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  font-family: "Mulish", sans-serif;
  line-height: 24px;
  margin-bottom: 8px;
  font-size: 15px;
  padding: 0;
  color: #a1a1a1;
  margin-bottom: 25px;
  transition: all 300ms linear;
  text-decoration: none;
}

.footer .footer-top .single-wedge .footer-links .last-wedge img {
  margin-right: 20px;
}

.footer .footer-top .single-wedge .footer-links .last-wedge:hover {
  color: #8fd2ee;
}

.footer .footer-top .extra-padding {
  padding-left: 120px;
  position: relative;
}

/* .footer .footer-top .extra-padding:after {
  position: absolute;
  top: 10px;
  left: 20px;
  height: 100%;
  width: 1px;
  content: "";
  background-color: #454545;
  margin: auto;
  bottom: 0;
} */

.footer-bottom .line-shape-top {
  border-top: 1px solid #454545;
  padding: 25px 0 25px 0;
}

.footer-bottom .line-shape-top .copyright-text {
  margin-bottom: 0px;
  color: #a1a1a1;
  font-family: "Mulish", sans-serif;
}
.logo-div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}
.social-logo {
  width: 20px;
  height: 20px;
  fill: #a1a1a1;
  cursor: pointer;
}
.container .footer-logo {
  width: 300px;
  margin: 40px auto;
}
.container .footer-logo img {
  width: 280px;
  height: 100px;
}
.default-services {
  position: relative;
  /* top: 50%;
  transform: translate(0%, -50%); */
  border: 1px solid #2525259c;
  border-radius: 10px;
  padding: 30px 45px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  row-gap: 35px;
  width: 88%;
  background: #ffffff;
  margin: 40px auto 0;
  margin-bottom: -100px;
}
.service-block {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.support-text {
  font-size: 20px;
  color: #252525;
  font-weight: 700;
}
.icon-bg {
  background: #252525;
  border: 1px solid #252525;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  margin: 0 auto;
  text-align: left;
  position: relative;
}
.support-icon {
  width: 26px;
  height: 26px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
