.details-accordion .accordion-item .accordion-header button {
    background-color: #F9F9F9;
    border-bottom: 1px solid #DEE2E6 !important;
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    font-weight: 800;
    padding: 10px 15px;
    min-height: 3.5rem;
    line-height: 1.25rem;
    box-shadow: none;
    color: #222222;
    border-radius: 5px !important;
}

.details-accordion .accordion-item .accordion-header .accordion-button.collapsed {
    border-bottom: 0px solid transparent !important;
}

.accordion-item:not(:first-of-type) {
    border-top: 1px solid #DEE2E6 !important;
}

.accordion-item {
    border-radius: 5px !important;
}

.details-accordion-body {
    background-color: #F9F9F9;
}

.form-control {
    font-family: 'Mulish', sans-serif;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    height: 45px;
    box-shadow: none;
    padding-left: 20px;
    color: #161C27;
    font-size: 14px;
    width: 100%;
}

.form-control:focus {
    border: 1px solid #000;
    box-shadow: none !important;
}

.save-btn {
    width: 100%;
    background-color: #000;
    border-radius: 5px;
    color: #FFF;
    font-family: 'Mulish', sans-serif;
    border-color: #000;
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom: 0px solid transparent !important;
}

.state-selection {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
}

.state-selection .states__control {
    height: 45px;
    border: 1px solid #E2E9E1 !important;
}

.state-selection .states__value-container {
    padding-left: 20px;
}

.shipping-options-list h2 {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.shipping-option label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.shipping-option label .option-detail {
    margin-left: 10px;
}

.shipping-option label .option-detail p {
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    color: #465B52;
}

.option-detail {
    margin-left: 10px;
}

.option-detail p {
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    color: #465B52;
}

.shipping-option p {
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    color: #465B52;
    margin-bottom: 0px;
}

.radio-control {
    width: 18px;
    height: 18px;
}

.option-detail

.order-review {
    position: sticky;
    top: 0px;
    border: 1px solid #E2E9E1;
    padding: 25px;
    border-radius: 10px;
}

.order-review h4 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 600;
    font-size: 22px;
}

.order-review table th, td {
    border: 2px solid rgb(225, 225, 225);
    border-bottom-width: 2px !important;
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 600;
    vertical-align: middle;
}

.order-review table h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0px;
}

.order-review table p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
    margin-top: 5px;
}

.final-text {
    font-size: 18px !important;
    font-weight: 700;
}

.saved-addresses h5 {
    font-size: 15px;
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
}

.saved-addresses .address-box {
    position: relative;
    border: 2px solid rgb(225, 225, 225);
    border-radius: 5px;
    padding: 15px 20px;
    cursor: pointer;
    margin-top: 5px;
}

.saved-addresses .address-box.selected {
    border: 2px solid green;
}

.saved-addresses .address-box.selected .selected-icon {
    display: block;
}

.saved-addresses .address-box:hover {
    border: 2px solid green;
}

.saved-addresses .address-box .selected-icon {
    display: none;
    position: absolute;
    top: 5px;
    right: 5px;
}

.saved-addresses .address-box .address-details {
    position: relative;
}

.saved-addresses .address-box .address-details p {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    margin-bottom: 0px;
}

.saved-addresses .address-box .address-details .address {
    margin-top: 15px;
}

.saved-addresses .address-box .address-details .actions {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 10px;
}

.saved-addresses .address-box .address-details .actions .edit-btn {
    padding: 0px;
    font-family: 'Mulish', sans-serif;
    color: grey;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
}

.saved-addresses .address-box .address-details .actions .edit-btn:hover {
    text-decoration: underline;
}


.saved-addresses .address-box .address-details .actions .delete-btn {
    padding: 0px;
    font-family: 'Mulish', sans-serif;
    color: red;
    font-style: italic;
    background-color: #FFF;
    border: 0px solid transparent;
    font-size: 14px;
    font-weight: 600;
    margin-left: 15px;
}

.saved-addresses .address-box .address-details .actions .delete-btn:hover {
    text-decoration: underline;
}

.address-modal-title {
    font-family: 'Mulish', sans-serif;
    font-size: 16px;
    font-weight: 700;
}

.address-modal-body .form-group {
    margin-bottom: 15px;
}

.address-modal-body .form-group label {
    font-family: 'Mulish', sans-serif;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 5px;
}

.add-address-btn {
    background-color: #3E3EF4;
    font-size: 15px;
    color: #FFF;
    font-family: 'Mulish', sans-serif;
}

.add-new-address-btn {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    padding: 0px;
    background-color: #FFF;
    border: 0px solid transparent;
    color: #000;
    font-size: 15px;
}

.add-new-address-btn:hover {
    background-color: #FFF;
    color: #000;
    text-decoration: underline;
}

.place-order-btn {
    font-family: 'Mulish', sans-serif;
    width: 100%;
    background-color: #000;
    border: 1px solid #000;
    margin-top: 15px;
}

.thanks-modal-content {
    text-align: center;
}

.thanks-modal-content h3 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
    text-align: center;
    margin-top: 15px;
}

.thanks-modal-content p {
    font-family: 'Mulish', sans-serif;
    text-align: center;
}

.thanks-modal-content .modal-order-details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgba(225, 225, 225, 0.5);
    border-radius: 5px;
    padding: 10px 15px;
}

.thanks-modal-content .modal-order-details .details-content h5 {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    margin-bottom: 0px;
    font-size: 17px;
}

.thanks-modal-content .modal-order-details .details-content p {
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
    margin-bottom: 0px;
    font-size: 14px;
}

.thanks-modal-header {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
}

.price-summary {
    border: 2px solid rgb(225, 225, 225);
    padding: 15px 20px;
}

.price-summary h5 {
    font-family: 'Mulish', sans-serif;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 15px;
}

.price-summary .price-details-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
}

.price-summary .price-details-row p {
    font-family: 'Mulish', sans-serif;
    margin-bottom: 0px;
}

.price-summary .price-total-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    border-top: 2px solid rgb(225, 225, 225);
    padding-top: 10px;
}

.price-summary .price-total-row p {
    font-family: 'Mulish', sans-serif;
    margin-bottom: 0px;
    font-weight: 700;
}

.wishlist-modal-header .wishlist-modal-title {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.wishlist-modal-body {
    font-family: 'Mulish', sans-serif;
}

.wishlist-modal-body p {
    margin-bottom: 0px;
}

.wishlist-modal-footer {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
}

.wholesale-modal-header .wholesale-modal-title {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
    font-size: 18px;
}

.wholesale-modal-body {
    text-align: center;
}

.wholesale-modal-body .thank-you-message {
    font-family: 'Mulish', sans-serif;
}

.wholesale-modal-body .thank-you-message h3 {
    font-weight: 700;
    margin-top: 10px;
}

.wholesale-modal-body .thank-you-message p {
    margin-bottom: 0px;
}

.social-media-handles h5 {
    font-family: 'Mulish', sans-serif;
    font-weight: 700;
}

.social-media-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border: 2px solid rgb(225, 225, 225);
    border-radius: 50%;
    margin-right: 15px;
}

.social-media-logo:hover {
    border: 2px solid #000;
}
.coupon-code-accordion .accordion-item {
    border: 1px solid #d5d5d5;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-header button p {
    margin-bottom: 0px;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-header button p span {
    color: #8e8e90;
    margin-right: 5px;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-header button p .text-black {
    font-weight: 600;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-header .accordion-button::after {
    background-size: 15px;
    margin-top: 5px;
    display: none;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-header .accordion-button.collapsed {
    box-shadow: none;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-header .accordion-button:not(.collapsed) {
    background-color: #fff;
    color: #000;
    box-shadow: none;
    border-bottom: 1px solid #d5d5d5;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-body p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
  }
  .coupon-code-accordion .accordion-item .coupon-code-accordion-body .coupon-code-btn {
    width: 100%;
    background-color: #000;
    font-size: 14px;
    font-weight: 600;
    border-color: #000;
  }
  
  .coupon-code-summary {
    border: 1px solid #22b25c;
    padding: 10px;
    border-radius: 7px;
    margin-bottom: 10px;
  }