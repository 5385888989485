.contact-us .contact-form-area h3 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.contact-us .contact-form-area p {
    font-family: 'Mulish', sans-serif;
    margin-bottom: 30px;
    color: #8E8E90;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
    font-weight: 400;
}

.form-control {
    font-family: 'Mulish', sans-serif;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    height: 45px;
    box-shadow: none;
    padding-left: 20px;
    width: 100%;
    font-size: 14px;
}

.form-control::placeholder {
    color: #8E8E90;
    font-size: 13px;
}

.textarea-control {
    height: 200px;
    font-family: 'Mulish', sans-serif;
    border: 1px solid #E2E9E1;
    border-radius: 4px;
    box-shadow: none;
    padding: 20px;
    font-size: #161C27;
    width: 100%;
}

.textarea-control::placeholder {
    color: #8E8E90;
    font-size: 13px;
}

.contact-form .submit-btn {
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    font-family: 'Mulish', sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 40px;
    color: #FFF;
    background-color: #000;
    border-radius: 5px;
}

.contact-form .submit-btn:hover {
    background-color: #005CB9;
    border-color: #005CB9;
    box-shadow: none;
}