/* CSS IMPORTS */
@import url("./components/header.css");
@import url("./components/footer.css");
@import url("./pages/homepage.css");
@import url("./pages/fabric.css");
@import url("./pages/register.css");
@import url("./pages/cart.css");
@import url("./pages/checkout.css");
@import url("./pages/product-detail.css");
@import url("./pages/contact-us.css");
@import url("./pages/my-account.css");
@import url("./responsive.css");
@import url("./pages/rewards.css");
@import url("./pages/miscellaneous-pages.css");

/* FONT IMPORTS */
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500;600;700;800;900;1000&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter+Tight:wght@100;200;300;400;500;600;700;800;900&display=swap");

@font-face {
  font-family: "Plein-Black";
  src: url("../assets/fonts/Plein-Black.woff");
  font-weight: 900;
}

@font-face {
  font-family: "Plein-Bold";
  src: url("../assets/fonts/Plein-Bold.woff");
  font-weight: 800;
}

.fraunces-black {
  font-family: "Fraunces", sans-serif;
  font-weight: 900;
}
.fraunces-bold {
  font-family: "Fraunces", sans-serif;
  font-weight: 700;
}
.fraunces-semibold {
  font-family: "Fraunces", sans-serif;
  font-weight: 600;
}

.SnackbarItem-message {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
}

body {
  overflow-x: hidden;
}

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  max-width: 100vw !important;
}

.search-flex {
  display: flex;
}

.cross-btn {
  position: absolute;
  right: 18%;
  margin-top: 3px;
  font-size: 35px;
}

@media (max-width: 1200px) {
  .cross-btn {
    position: absolute;
    right: 18px !important;
    margin-top: 3px;
  }
}

.px-4 {
  padding: 0 14px;
}

.pl-4 {
  padding-left: 14px;
}

.px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.pt-0 {
  padding-top: 0px !important;
}

.pl-0 {
  padding-left: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.pt-40 {
  padding-top: 40px !important;
}

.pt-60 {
  padding-top: 60px !important;
}

.pt-50 {
  padding-top: 50px !important;
}

.pb-50 {
  padding-bottom: 50px !important;
}

.pt-100 {
  padding-top: 100px !important;
}

.pt-30 {
  padding-top: 30px !important;
}

.pb-30 {
  padding-bottom: 30px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mb-50 {
  margin-bottom: 50px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.ml-2 {
  margin-left: 10px !important;
}

.m-auto {
  margin: auto !important;
}

.section-padding {
  padding: 25px 0;
}

a,
button,
img,
input,
span,
h4 {
  transition: all 0.3s ease 0s;
}

.full-width-container {
  max-width: 100% !important;
}

video {
  width: 100%;
  height: 100%;
}

.text-black {
  color: #000 !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.font-weight-700 {
  font-weight: 700 !important;
}

.same-height {
  flex: 0 0 33.3333333%;
  max-width: 33.3333333%;
  position: relative;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  display: flex;
}

.p-relative {
  position: relative !important;
}

/* SCROLLBAR CSS */
body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

body::-webkit-scrollbar-thumb {
  background-color: #000;
  outline: 1px solid #000;
  border-radius: 10px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.form-group {
  position: relative;
}

.password-icon {
  background-color: #fff;
  padding: 0px;
  position: absolute;
  top: 10px;
  right: 15px;
  border: 0px solid transparent;
}

.password-icon:hover {
  background-color: #fff;
}

.password-icon svg {
  fill: #000;
}

.validation-error {
  font-family: "Mulish", sans-serif;
}

.return-to-home {
  width: 200px !important;
  background-color: #000;
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  border-color: #000;
}

.topleft {
  position: absolute;
  top: -10px;
  left: -10px;
}

.sold-out-image {
  width: 70px !important;
  height: 70px !important;
  position: absolute !important;
  top: 0px !important;
  margin: 0px !important;
}

.infinite-scroll-component {
  padding-top: 10px;
  padding-left: 10px;
}

.mobile-banner {
  top: 0px !important;
  padding: 0px !important;
  margin-top: 0px !important;
}

.responsive-button {
  margin-top: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
@import url(//fonts.googleapis.com/css?family=Montserrat:300,400,500);
.testimonial1 {
  font-family: "Montserrat", sans-serif;
  color: #8d97ad;
  font-weight: 300;
  width: 95%;
  min-height: 430px;
  /* position: relative; */
  background: #f8f9fa;
  border-radius: 20px;
  margin: 40px auto;
  padding: 20px;
}

.testimonial1 h1,
.testimonial1 h2,
.testimonial1 h3,
.testimonial1 h4,
.testimonial1 h5,
.testimonial1 h6 {
  color: #3e4555;
}

.testimonial1 .bg-light {
  background-color: #f4f8fa !important;
}

.testimonial1 .subtitle {
  color: #8d97ad;
  line-height: 24px;
}

.testimonial1 .testi1 .card-body {
  padding: 35px;
}

.testimonial1 .testi1 .thumb {
  padding: 10px 20px 10px;
  padding-left: 90px;
  margin-left: -35px;
}

.testimonial1 .testi1 .thumb .thumb-img {
  width: 60px;
  left: 20px;
  top: -10px;
}

.testimonial1 .testi1 h5 {
  line-height: 30px;
  font-size: 18px;
}

.testimonial1 .testi1 .devider {
  height: 1px;
  background: rgba(120, 130, 140, 0.13);
  width: 100px;
}

.testimonial1 .bg-success-gradiant {
  background: #2cdd9b;
  background: -webkit-linear-gradient(
    legacy-direction(to right),
    #2cdd9b 0%,
    #1dc8cc 100%
  );
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#2cdd9b),
    to(#1dc8cc)
  );
  background: -webkit-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: -o-linear-gradient(left, #2cdd9b 0%, #1dc8cc 100%);
  background: linear-gradient(to right, #2cdd9b 0%, #1dc8cc 100%);
}

.testimonial1 .card.card-shadow {
  -webkit-box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
  box-shadow: 0px 0px 30px rgba(115, 128, 157, 0.1);
}

.testimonial1 .owl-theme .owl-dots .owl-dot.active span,
.testimonial1 .owl-theme .owl-dots .owl-dot:hover span {
  background: #316ce8;
}
@media (max-width: 768px) {
  .infinite-scroll-component {
    padding-left: 0;
  }
}