/* ABOUT US */
.about-us h1 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
}

.about-us p {
    font-family: 'Mulish', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

/* SHIPPING POLICY */
.shipping h4 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
}

.return-policy h4 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
}

.return-policy ul li {
    font-size: 18px;
    font-family: 'Mulish', sans-serif;
    font-weight: 800;
}

.privacy h4 {
    font-family: 'Inter Tight', sans-serif;
    font-weight: 700;
}

.privacy ul li {
    font-size: 18px;
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
}

.privacy ol li {
    font-size: 18px;
    font-family: 'Mulish', sans-serif;
    font-weight: 500;
}