.fabric-heading-desc .fabric-heading {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
  font-size: 32px;
  margin-bottom: 15px;
}

.fabric-heading-desc .fabric-description {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  margin-bottom: 0px;
}

.shop-fabrics .sidebar-widget {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  margin: 0 0 20px;
  border: 1px solid #f3f3f3;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.row > div {
  margin-bottom: 1rem !important;
}
.shop-fabrics .sidebar-widget .sidebar-title {
  font-size: 18px;
  display: block;
  text-transform: capitalize;
  font-weight: 600;
  font-family: "Mulish", sans-serif;
  color: #454545;
  padding: 14px 0px 15px 20px;
  background: #f3f3f3;
}

.shop-fabrics .sidebar-widget .sidebar-category {
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.shop-fabrics .sidebar-widget .sidebar-category ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.shop-fabrics .sidebar-widget .sidebar-category .category-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.shop-fabrics .sidebar-widget .sidebar-category .category-list.active {
  background-color: rgba(241, 90, 41, 0.1);
  border: 1px solid rgb(241, 90, 41);
}

.shop-fabrics .sidebar-widget .sidebar-category .category-list:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.shop-fabrics .sidebar-widget .sidebar-category .category-list .color-category {
  height: 15px;
  width: 15px;
  border-radius: 10px;
  border: 1px solid #3c2415;
}

.shop-fabrics .sidebar-widget .sidebar-category .category-list .color-name {
  font-family: "Mulish", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #474747;
  margin-left: 10px;
  margin-bottom: 0px;
}

.product-box {
  height: 100%;
  position: relative;
  border: 1px solid rgba(34, 34, 34, 0.15);
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.product-box:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.product-box img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 320px;
  transition: transform 0.3s ease;
}
.product-box:hover .product-box img {
  transform: scale(1.05);
}
.product-box .product-name {
  text-align: left;
  padding: 20px;
}

.product-box .product-name h5 {
  font-family: "Mulish", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.product-box .product-name p {
  font-family: "Mulish", sans-serif;
  font-weight: 600;
  color: #474747;
  margin-bottom: 0px;
}

.pagination-list {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.pagination-list li {
  color: #d5d5d5;
  padding: 0;
  height: 40px;
  line-height: 40px;
  background: #fff;
  display: inline-block;
  width: 40px;
  border-radius: 5px;
  text-align: center;
  vertical-align: top;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  font-weight: 500;
  border: 1px solid #dee2e6;
  margin-right: 10px;
}

.pagination-list li:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.pagination-list li.selected {
  background-color: #005cb9;
  color: #fff;
  box-shadow: 0 5px 15px 0 rgb(234 170 133 / 50%);
  border-color: #005cb9;
}

.filters {
  width: 25%;
  position: sticky;
  top: 0;
  overflow: auto;
}

.products-list {
  position: sticky;
  top: 20px;
  height: 100%;
  width: 75%;
}
.topleft {
  position: absolute;
  top: -10px;
  left: -10px;
}

.sold-out-image {
  width: 70px !important;
  height: 70px !important;
  position: absolute !important;
  top: 0px !important;
  margin: 0px !important;
}
.wishlist-option {
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.cart-option {
  top: 55px;
}
.sold-out-text {
  display: none;
}

.wishlist-option-detail {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.display-hover {
  display: none;
}

.wishlist-option:hover .display-not-hover {
  display: none;
  transition: 0.2ms ease-in;
}

.wishlist-option:hover .display-hover {
  display: block;
  transition: 0.2ms ease-in;
}

.wishlist-option-detail:hover .display-not-hover {
  display: none;
  transition: 0.2ms ease-in;
}

.wishlist-option-detail:hover .display-hover {
  display: block;
  transition: 0.2ms ease-in;
}

.selected-filters {
  width: 100%;
  font-family: "Mulish", sans-serif;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.selected-filters p {
  font-size: 14px;
  font-weight: 700;
}

.selected-filters .filters-list {
  margin-left: 10px;
}

.selected-filters .filters-list .filter-box {
  border: 1px solid rgba(34, 34, 34, 0.15);
  padding: 0px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.selected-filters .filters-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.selected-filters .filters-list .filter-box {
  margin-right: 10px;
  margin-bottom: 5px;
}

.selected-filters .filters-list .filter-box p {
  margin-bottom: 0px;
  font-weight: 400;
}

.selected-filters .filters-list .filter-box .close-button {
  margin-left: 8px;
  padding: 0px;
  background-color: #fff;
  border: 1px solid #fff;
}

.selected-filters .filters-list .filter-box .close-button svg {
  margin-top: -3px;
}

.infinite-scroll-component {
  overflow: hidden !important;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.no-results-found h5 {
  font-family: "Inter Tight", sans-serif;
  font-weight: 700;
  font-size: 25px;
}

.no-results-found p {
  font-family: "Mulish", sans-serif;
  margin-bottom: 0px;
}

.no-results-found .no-category-found {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid rgb(225, 225, 225);
  border-radius: 5px;
  padding: 15px;
}

.no-results-found .no-category-found:hover {
  border: 2px solid #005cb9;
}

.no-results-found .no-category-found h5 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0px;
}

.colors-filter-accordion .colors-filter-accordion-item {
  border: 1px solid #f3f3f3 !important;
}

.colors-filter-accordion
  .colors-filter-accordion-item
  .colors-filter-accordion-header
  button {
  background-color: #f3f3f3;
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  color: #000;
  font-weight: 600;
}

.colors-filter-accordion
  .colors-filter-accordion-item
  .colors-filter-accordion-header
  button:focus {
  box-shadow: none !important;
}

.colors-filter-accordion .colors-filter-accordion-body {
  padding: 15px;
  padding-top: 5px;
  padding-bottom: 10px;
}

.colors-filter-accordion .colors-filter-accordion-body .sidebar-widget {
  border: 0px solid !important;
}

.colors-filter-accordion
  .colors-filter-accordion-body
  .sidebar-widget
  .sidebar-category {
  padding: 0px;
}
