.carousel-container {
  position: relative;
  max-width: 90%;
  margin: 0 auto;
}

.carousel .slide {
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: scale(0.9);
  opacity: 0.6;
  filter: blur(1px);
  padding: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel .slide.selected {
  transform: scale(1.1);
  opacity: 1;
  filter: none;
  z-index: 2;
}

.carousel .thumb {
  transition: border 0.15s ease-in;
  display: inline-block;
  margin-right: 6px;
  white-space: nowrap;
  overflow: hidden;
  border: 3px solid #fff;
  padding: 2px;
  min-width: 200px;
  text-align: end;
  min-height: 50px;
  margin: 10px 6px auto 0 !important;
}

.carousel .thumb:hover {
  border: none !important;
}

.carousel .slide.prev,
.carousel .slide.next {
  transform: scale(1.1);
  opacity: 0.8;
  z-index: 1;
  filter: blur(0.5px);
}

.carousel .carousel-slider {
  overflow: visible;
}

.thumb-img {
  width: 60px !important;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  position: absolute !important;
  left: -10px;
  top: -10px;
  border: 4px solid white;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}

.card-shadow {
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  padding: 15px;
  background: white;
}

h5 {
  font-size: 14px;
  color: #333;
  margin-bottom: 10px;
}

h6 {
  font-size: 12px;
  color: #666;
}

.divider {
  width: 40px;
  height: 2px;
  background-color: #000;
  display: block;
  margin: 8px 0;
}

.carousel .slide,
.carousel .slide.selected,
.carousel .slide.prev,
.carousel .slide.next {
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
}

.carousel .control-prev.control-arrow,
.carousel .control-next.control-arrow {
  display: none !important;
}

/* .carousel .slide {
  margin-right: 10px;
} */

.carousel .slide.selected {
  margin: 0;
}
.carousel .slider-wrapper.axis-horizontal .slider {
  min-height: 280px !important;
}
.main-container {
  width: 100%;
  min-height: 250px;
  background-color: #fff;
  border: 1px solid rgb(204, 203, 203);
  border-radius: 5px;
  padding: 15px;
  margin-inline: auto;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.customer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  /* background-color: aliceblue; */
  border-radius: 12px;
  padding: 10px;
  /* width: fit-content; */
  margin-top: auto;
}
.customer-desc {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
}
.customer img {
  width: 40px !important;
  height: 40px;
  border-radius: 1000000px;
  overflow: hidden;
}
.customer p {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 2px !important;
}
@media (max-width: 1024px) {
  .carousel .slide.selected {
    transform: scale(0.9);
  }
}
@media (max-width: 768px) {
  .carousel .slide.selected {
    transform: scale(1);
  }
}
@media (max-width: 430px) {
  .carousel .thumb {
    min-width: 130px;
  }

  .thumb-img {
    width: 50px !important;
    height: 50px;
  }

  .card-shadow {
    padding: 10px;
  }

  h5 {
    font-size: 12px;
    margin-bottom: 8px;
  }

  h6 {
    font-size: 10px;
  }

  .divider {
    width: 30px;
    height: 1px;
  }
}
